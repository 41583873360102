import { Button } from '@ph-react-ui/core';
import { useQueryClient } from '@tanstack/react-query';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

export function Payments() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { month, year } = useMemo(() => {
    const currentDate = new Date();
    return {
      month: currentDate.getMonth() + 1,
      year: currentDate.getFullYear(),
    };
  }, []);

  const onNavigateToFoodPaymentPage = () => {
    queryClient.invalidateQueries({ queryKey: ['food-price-plan'] });
    navigate(`food?year=${year}&month=${month}`);
  };

  const onNavigateToTransportPaymentPage = () => {
    navigate(`transport?year=${year}&month=${month}`);
  };
  const onNavigateToTransportHistoryPage = () => {
    navigate(`transport/history?year=${year}&month=${month}`);
  };
  // const onNavigateToSchoolTaxesPage = () => {
  //   navigate('school-taxes');
  // };

  // const onNavigateToSchoolTaxPaymentsHistory = () => {
  //   navigate('/dashboard/payments/school-taxes/history');
  // };

  return (
    <div className="payments fixed-width">
      {/* <div className="payments__item">
        <div className="payments__item__titles">
          <h2 className="payments__item__titles__h2">
            {t('PAYMENTS.TITLES.SCHOOL_TAXES')}
          </h2>
          <button
            className="payments__item__titles__button"
            onClick={onNavigateToSchoolTaxPaymentsHistory}
          >
            {t('ACTIONS.PAYMENT_HISTORY')}
          </button>
        </div>
        <div className="payments__item__actions">
          <Button
            compact
            className="payments__item__actions__pay"
            onClick={onNavigateToSchoolTaxesPage}
          >
            {t('ACTIONS.PREVIEW_PAYMENT')}
          </Button>
        </div>
      </div> */}
      <div className="payments__item">
        <div className="payments__item__titles">
          <h2 className="payments__item__titles__h2">
            {t('PAYMENTS.TITLES.FOOD')}
          </h2>
        </div>
        <div className="payments__item__actions">
          <Button
            compact
            className="payments__item__actions__pay"
            onClick={onNavigateToFoodPaymentPage}
          >
            {t('ACTIONS.PREVIEW_PAYMENT')}
          </Button>
        </div>
      </div>
      <div className="payments__item">
        <div className="payments__item__titles">
          <h2 className="payments__item__titles__h2">
            {t('PAYMENTS.TITLES.TRANSPORT')}
          </h2>
          <button
            className="payments__item__titles__button"
            onClick={onNavigateToTransportHistoryPage}
          >
            {t('ACTIONS.PAYMENT_HISTORY')}
          </button>
        </div>
        <div className="payments__item__actions">
          <Button
            compact
            className="payments__item__actions__pay"
            onClick={onNavigateToTransportPaymentPage}
          >
            {t('ACTIONS.PREVIEW_PAYMENT')}
          </Button>
        </div>
      </div>
    </div>
  );
}
