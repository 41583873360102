import { Button, type SimpleDate, useNotifications } from '@ph-react-ui/core';
import { useQueryClient } from '@tanstack/react-query';
import { format } from 'date-fns';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useLocation, useSearchParams } from 'react-router-dom';
import { useMarkFoodAsPaid } from '../../hooks/taxes/food/admin/useMarkAsPaid';
import { useGetPreviousUnpaidTax } from '../../hooks/taxes/school-taxes/admin/useGetPreviousUnpaidTax';
import { useMarkSchoolTaxesAsPaid } from '../../hooks/taxes/school-taxes/admin/useMarkSchoolTaxesAsPaid';
import { useMarkTransportationAsPaid } from '../../hooks/taxes/transportation/useMarkTransportationAsPaid';
import { useCancellationDialog } from '../../hooks/useCancellationDialog';
import { useModal } from '../../hooks/useModal';
import { mapSimpleDateToString } from '../../services/mapSimpleDateToString';
import { DatePicker, Textarea } from '../../utils/components/hoc-components';
import { CONFIRM_BANK_PAYMENT } from '../../utils/constants/users/modals';
import { TaxesTypes } from '../../utils/enums/taxes-types.enum';
import {
  getCommonValidators,
  VALIDATION as V,
} from '../../utils/inputValidators';
import { Dialog } from '../shared/components/Dialog';

interface FormProps {
  paidAt: SimpleDate;
  description: string;
}

interface FormData {
  paidAt: string;
  description: string;
  studentIds?: string[];
  mealMenuId?: string;
  year?: number;
  month?: number;
  ids?: string[];
}

export function MarkAsPaidDialog() {
  const location = useLocation();
  const { open: openCancellationDialog } = useCancellationDialog();
  const { t } = useTranslation();
  const { close: closeModal } = useModal(CONFIRM_BANK_PAYMENT);
  const [searchParams] = useSearchParams();
  const users = searchParams.get('userIds');
  const mealMenuId = searchParams.get('mealMenuId') ?? '';
  const month = searchParams.get('month') ?? '';
  const year = searchParams.get('year') ?? '';
  const methods = useForm<FormProps>({
    defaultValues: {
      paidAt: '' as unknown as SimpleDate,
      description: '',
    },
  });
  const queryClient = useQueryClient();
  const notification = useNotifications();
  const currentDate = new Date();
  const markFoodAsPaid = useMarkFoodAsPaid();
  const markTransportationAsPaid = useMarkTransportationAsPaid();
  const markSchoolTaxesAsPaid = useMarkSchoolTaxesAsPaid();

  const ids = users?.split('&');

  const { data: previousUnpaidTaxes } = useGetPreviousUnpaidTax(
    ids || [],
    location.pathname.includes('school-taxes')
  );

  const hasPreviousUnpaidTaxes = Boolean(previousUnpaidTaxes);
  const { isDirty } = methods.formState;

  let taxesType = TaxesTypes.Food;
  let mutation = markFoodAsPaid;

  if (location.pathname.includes('transportation')) {
    taxesType = TaxesTypes.Transportation;
    mutation = markTransportationAsPaid;
  }

  if (location.pathname.includes('school-taxes')) {
    taxesType = TaxesTypes.PromisedSchoolTaxes;
    mutation = markSchoolTaxesAsPaid;
  }

  const onSubmit = (data: FormProps) => {
    if (users) {
      const selectedIds = users.split('&');
      const formData: FormData = {
        ...data,
        paidAt: mapSimpleDateToString(data.paidAt) ?? '',
      };

      if (taxesType === TaxesTypes.Food) {
        formData['mealMenuId'] = mealMenuId;
      }
      if (taxesType === TaxesTypes.Transportation) {
        formData.month = +month;
        formData.year = +year;
      }
      if (taxesType === TaxesTypes.PromisedSchoolTaxes) {
        formData.ids = selectedIds;
      } else {
        formData.studentIds = selectedIds;
      }

      mutation
        .mutateAsync(formData)
        .then(() => {
          notification.success(t('TAXES.DIALOGS.MESSAGES.MARKED_AS_PAID'));
          queryClient.invalidateQueries({ queryKey: [taxesType] });
          closeModal();
        })
        .catch((resError) =>
          notification.danger(t(`NETWORK_ERRORS.${resError.errors[0]}`))
        );
    }
  };

  const onCloseModal = () => {
    if (isDirty) {
      openCancellationDialog();
    } else {
      closeModal();
    }
  };

  return (
    <Dialog
      dialogHeader={t('TAXES.DIALOGS.TITLES.CONFIRM_BANK_PAYMENT')}
      onClose={onCloseModal}
      actionButton={
        <Button
          type="submit"
          onClick={methods.handleSubmit(onSubmit)}
          disabled={hasPreviousUnpaidTaxes}
        >
          {t('ACTIONS.CONFIRM')}
        </Button>
      }
    >
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <div className="dialog__confirm__content dialog__confirm__content-payment">
            {hasPreviousUnpaidTaxes ? (
              <p>
                {t('TAXES.DIALOGS.MESSAGES.UNPAID_TAXES', {
                  period: `${t(
                    `MONTHS.${format(
                      new Date(previousUnpaidTaxes?.fromDate || ''),
                      'MMMM'
                    ).toUpperCase()}`
                  )} ${new Date(
                    previousUnpaidTaxes?.fromDate || ''
                  ).getFullYear()}`,
                })}
              </p>
            ) : (
              <></>
            )}
            <DatePicker
              label={t('TAXES.DIALOGS.LABELS.DATE') ?? ''}
              name="paidAt"
              disabled={hasPreviousUnpaidTaxes}
              rules={getCommonValidators([V.REQUIRED])}
              maxDate={{
                date: currentDate.getDate(),
                month: currentDate.getMonth(),
                year: currentDate.getFullYear(),
              }}
            ></DatePicker>
            <Textarea
              name="description"
              label={t('TAXES.DIALOGS.LABELS.NOTES') ?? ''}
              className="dialog__confirm__content__textarea"
              rows={15}
              disabled={hasPreviousUnpaidTaxes}
            />
          </div>
        </form>
      </FormProvider>
    </Dialog>
  );
}
